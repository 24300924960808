    .footer{
        display: flex;
        justify-content: flex-end;
        min-height: 500px;
        padding: 16px;
        padding-top: 4rem;
        background: linear-gradient(
        30deg,
        #a84acb,
        #a47ee9,
        #eb30d5,
        #9a75ae,
        #be27d5,
        #6d06e3
        );
        animation: gradient 10s linear infinite;
        animation-direction: alternate;
        background-size: 600% 100%;
        flex-wrap: wrap;
        overflow-x: hidden;
    }

    /* Media query for tablets (max-width: 986px) */
@media (max-width: 988px) {
    .footer  {
        justify-content: space-between; /* Reset margin to stack sections on top of each other */
    }
    
    .footer .left-section {
        width: max-content;
        }

    .footer .section {
        width:100%;
    }
    }    

.footer .left-section {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    margin-right: 3rem;
    max-width: 30rem;
  }

  .footer .left-section .button {
    margin-top: 3rem;
    background-color: white;
    width: 25em;
    height: 3rem;    
    border-radius: 10px;
    box-shadow: 1px 1px 3px 1px rgb(201, 200, 200);
    color: black;
    font-family: 'Montserrat';
    font-weight: 700;
  }

  .footer .left-section .button:hover {
    transform: scale(1.03);
    transition: 0.4s ease-in-out;
  }


    
    .footer .section {
        display: flex;
        flex-direction: column;
        margin-top: 90px;
        margin-right: 4rem;
    }
    
    .footer .list {
        list-style-type: none;
        padding: 0;
    }
    
    .footer .list-item {
        margin: 4px 0;
    }
    
    .footer .stack-lists {
        flex-direction: column;
        text-align: center;
    }

    /* footer.css */

    /* Other styles */
    .footer .link {
        color: white;
        text-decoration: none;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        display: inline-block;
    }
    
    .footer .link::before,
    .link::after {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: white;
        transition: width 0.3s ease;
    }
    
    .footer .link::before {
        right: 100%;
        transform: translateX(100%);
    }
    
    .footer .link::after {
        left: 100%;
        transform: translateX(-100%);
    }
    
    .footer .link:hover::before,
    .link:hover::after {
        width: 100%;
        transform: translateX(0);
    }
    
    .icon-logos {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
      }
      
      .icon-logo {
        width: 20px; /* Adjust the size as needed */
        height: 20px;
        margin: 10px; /* Adjust the margin as needed */
      }
    
    @keyframes gradient {
        0% {
        background-position: 0%;
        }
        100% {
        background-position: 100%;
        }
    }
    