.profile .home-container {
    background-image:radial-gradient(circle farthest-corner at 125% 100%,#fff 19%,hsla(0,0%,100%,0) 41%),linear-gradient(38deg,hsla(0,0%,100%,0) 78%,hsla(0,0%,100%,.69)),linear-gradient(45deg,#fff 10%,hsla(0,0%,100%,0) 22%),linear-gradient(0deg,#fff,hsla(0,0%,100%,0) 91%),radial-gradient(circle farthest-corner at 0 -50%,hsla(0,0%,100%,0) 15%,rgba(30,199,255,.4) 38%,hsla(0,0%,100%,0) 65%),repeating-linear-gradient(214deg,hsla(0,0%,100%,0),rgba(0, 20, 241, 0.3) 25%,hsla(0,0%,100%,0) 71%),repeating-linear-gradient(212deg,hsla(0,0%,100%,0) 51%,rgba(100,57,232,.334) 64%,hsla(0,0%,100%,0) 80%);
    height: 90vh;  
    margin-top: 0px
}

.profile .home-container h1 {
    font-family: 'DM Sans';
    font-weight: 600;
    font-size: 3.5rem;
    text-align: center;
}

.profile .home-container button {
    margin-top: 1rem;
    background-color: #be123c;
    color: white;
    width: 200px;
    height: 45px;
    border-radius: 30px;
}

    
.profile .home-container button:hover {
    background-color: rgb(103, 3, 28)
}



.profile .tests-container .card {
    transition: transform 0.5s ease-in-out;
}

.profile .tests-container .card:hover {
    transform: scale(1.03);
    cursor: pointer;
}


.consignes .home-container {
    background-image:radial-gradient(circle farthest-corner at 125% 100%,#fff 19%,hsla(0,0%,100%,0) 41%),linear-gradient(38deg,hsla(0,0%,100%,0) 78%,hsla(0,0%,100%,.69)),linear-gradient(45deg,#fff 10%,hsla(0,0%,100%,0) 22%),linear-gradient(0deg,#fff,hsla(0,0%,100%,0) 91%),radial-gradient(circle farthest-corner at 0 -50%,hsla(0,0%,100%,0) 15%,rgba(30,199,255,.4) 38%,hsla(0,0%,100%,0) 65%),repeating-linear-gradient(214deg,hsla(0,0%,100%,0),rgba(0, 20, 241, 0.3) 25%,hsla(0,0%,100%,0) 71%),repeating-linear-gradient(212deg,hsla(0,0%,100%,0) 51%,rgba(100,57,232,.334) 64%,hsla(0,0%,100%,0) 80%);
    margin-top: 0px
}
.consignes .home-container h1 {
    font-family: 'DM Sans';
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
}

.consignes .home-container h2 {
    color:  #be123c;
    font-family: 'DM Sans';
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-top: 20rem;
}



@media (max-width: 600px) {
    .profile .home-container h1  {
        width: auto;

    }

    .consignes .home-container h1  {
        width: auto;

    }

}

