.expert-co h1 {
    font-family: 'Montserrat';
    font-weight: 700; /* Remove single quotes around numeric values */
    font-size: 5.5em; /* Remove single quotes around numeric values */
    text-align: center; /* Remove single quotes around property values */
    margin: 0 auto;
    letter-spacing: 1.2px;
}

.expert-co h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2em; 
    text-align: center;
    color: #174666;
    max-width: 600px; 
    margin: 0 auto;
    margin-bottom: 1rem;
    letter-spacing: 1.2px;
    
}

.expert-co h3 {
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 1.2px;
}

.expert-co .outer-div {
    min-height: 300px; /* Set a minimum height */
    background-image: url('./../images/background-expert-co.png');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
}
.expert-co .you-want {
    min-height: 300px; /* Set a minimum height */
    background-image: url('./../images/background-expert-co.png');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    max-height: 400px;
}

.expert-co .purchase:hover {
    background-color: #245f86 !important;
}


@media (max-width: 500px) {
    .expert-co h1 {
        font-size: 50px;
        max-width: 300px;
    }

    .expert-co h2 {
        font-size: 25px;
        max-width: 300px;
    }
    
}

