.dropdown-toggle::after {
    display: none !important; 
}

.dropdown-menu.show {
    border: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
};

.my-navbar #mytoggler.navbar-toggler,
.my-navbar #mytoggler.navbar-toggler:focus,
.my-navbar #mytoggler.navbar-toggler:active {
    outline: none !important; 
    border: none !important;
    box-shadow: none !important;
}

.show {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.collapsing {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.nav-link-active {
  color: #9A1FD2 !important; /* Your chosen highlight color */
}

.nav-main-item, .nav-main-item.dropdown .dropdown-toggle {
  color: black; /* Purple color on hover for main items */
}


.nav-main-item:hover, .nav-main-item.dropdown:hover .dropdown-toggle {
  color: #9A1FD2; /* Purple color on hover for main items */
}


.nav-main-item:hover {
  color: #9A1FD2 !important;/* Purple color on hover for main items */

}

.nav-main-item.active-parent .dropdown-toggle {
  color: #9A1FD2 !important; /* Specific color for parent when subitem is active */
}

/* Style for dropdown items on hover */
.nav-dropdown-item:hover, .nav-dropdown-item.active {
  color: #cc70f6 !important; /* Ensures dropdown items also have a hover effect */
  background: none !important;
}

@media (max-width: 991px) { /* Adjust this value based on when your navbar collapses */
    .dropdown-menu {
      position: static !important; /* Makes the dropdown part of the normal document flow */
    }
  
    .nav-item .dropdown-menu {
      display: none; /* Hide dropdown by default */
      width: 100%; /* Make dropdown full width */
      margin-top: 0.5rem; /* Add a little space between the parent item and submenu */
    }
  
    .nav-item:hover .dropdown-menu {
      display: block; /* Show dropdown on hover */
    }
    .dropdown-toggle::after {
      display:inline-block !important;
      color: black;
  }
  }
  