.OTP-form input {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
    font-size: 3rem;
    font-family: Public Sans;
    font-weight: 500;
    border-style: solid;
    border-color: rgb(206, 206, 206);
    border-radius: 5px;
    text-align: center;
}

.OTP-form input:focus {
    outline: none;
    border-color: rgb(105, 108, 255);
}

@media (max-width: 600px) {
    .OTP-form input {
        width: 3rem !important;
        height: 3rem;
        margin-right: 0.75rem;
        
    }

    .OTP-form .OTP-inputs {
        display: flex;
        justify-content: center;
        align-items: center;
}
    .OTP-form button {
        margin-left: 10px;
    }

    .OTP-form h6 {
        margin-left: 10px
    }

    .presentation p {
        margin-left: 10px
    }

}